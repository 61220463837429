import React, { Fragment } from "react";
import Privacy from "../../components/Privacy/Privacy";

export const PrivacyPage = () => {
    return (
        <Fragment>
            <Privacy/>
        </Fragment>
    );
};
