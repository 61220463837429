import React from 'react';
import NavContainer from "../NavContainer/NavContainer";
// import PropTypes from 'prop-types'

export function Footer() {
  return (
    <footer>
        <NavContainer direction="column">
            <p>hola</p>
        </NavContainer>
    </footer>
  )
}

// Footer.propTypes = {}

// export default Footer
